import React, { Component } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import appleIcon from '../images/apple-icon.svg'
const isBrowser = typeof window !== `undefined`


class ArRedirect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      model: props.data.arPath
    }
    this.inputRef = React.createRef()
  }
  componentDidMount(){
    let os = ''
    if(isBrowser){
      os = this.getMobileOS()
    }
    if (os === "Android") {
      window.location.replace(
        `intent://arvr.google.com/scene-viewer/1.0?file=https://virtual-showroom.forms-surfaces.net/AR/${
          this.state.model && this.state.model
        }.glb&mode=ar_only#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://virtual-showroom.forms-surfaces.net;end;`
      );
    } else if (os === "iOS") {
      this.inputRef.current.click()
    }
  }

  getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    } else if (/iPad|iPhone|iPod|iPad Simulator|iPhone Simulator|iPod Simulator/i.test(ua)) {
      if(/Instagram|FBIOS|Twitter|LinkedInApp/i.test(ua)){
        return "Other"
      }else{
        return "iOS"
      }
    }else{
      return "Other";
    }
  }

  render() {
    let uaCol = null
    if(isBrowser){
      let ua = navigator.userAgent
      uaCol = <div className="row">
      <div className="col text-center">
      {ua}
      </div></div>
    }
    return(
      <div className="wrapper" style={{padding: '3rem'}}>
        <div className="content m-4">
          <div className="row">
            <div className="col text-center">
              <a
                rel="ar"
                download
                href={`https://virtual-showroom.forms-surfaces.net/AR/${
                  this.state.model && this.state.model
                }.usdz`}
                ref={this.inputRef}
              >
                <img src={appleIcon} style={{width: "1px",height:"1px"}} />
                <i className="fab fa-apple"></i> Download .usdz
              </a>
            </div>
            <div className="col text-center">
              <a
                href={`intent://arvr.google.com/scene-viewer/1.0?file=https://virtual-showroom.forms-surfaces.net/AR/${
                  this.state.model && this.state.model
                }.glb#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://virtual-showroom.forms-surfaces.net;end;`}
                download
              >
                <i className="fab fa-android"></i> Download .glb
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ArRedirect
