import React, { Component } from 'react'
import { Router } from '@reach/router'
import Layout from '../components/layout'
import ArRedirect from '../components/ar-redirect'
import SEO from '../components/seo'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

const App = ({ pageContext, props}) => {
  const location = useLocation()
  const pageQuery = queryString.parse(location.search)
  console.log(pageContext)
  return(
    <Layout>
    <SEO title={pageContext.productTitle}/>
    <ArRedirect data={pageContext} />
    </Layout>
  )
}

export default App
